import { useEffect, useMemo, useRef } from 'react';
import SinglePage from './components/SinglePage/SinglePage';
import PopupPage from './components/PopupPage/PopupPage';
import Script from 'react-load-script';
import LoadingBrickPopup from './components/LoadingBrick/LoadingBrickPopup';
import EditCardInfoPopup from './components/EditCardInfoPopup/EditCardInfoPopup';
import { useSelector, useDispatch } from 'react-redux';
import useLocalStore from './hooks/useLocalStore';
import mainAction from './reducer/action/mainAction';
import FJUtil from './util/FJUtil';
import FJConfig from './config/FJConfig';
import FJNetworkStore from './store/FJNetworkStore';
import useIsMobile from './hooks/useIsMobile';
import FJViewConstant from './constant/FJViewConstant';
import FJMobilePayAction from './reducer/action/FJMobilePayAction';
import FJMessageStore from './store/FJMessageStore';
import FJLocalStore from './store/FJLocalStore';
import FJMobile from './components/FJMobile/FJMobile';

let f_isFirst = true;

const AppContent = () => {
    useLocalStore();
    const dispatch = useDispatch();

    const handleScriptErrorStripe = () => {
        console.log('script err');
    };
    const handleScriptLoadStripe = () => {
        console.log('script load');
    };
    const isMobile = useIsMobile();
    let cityCodeRef = useRef('cn');

    const {
        show,
        isEditCardInfo,
        cardParams,
        loading,
        method,
        purchase,
        mobilePayStep,
        entry,
        creditsPayData,
        dataInfo,
    } = useSelector(state => {
        return {
            show: state.mainReducer.show,
            isEditCardInfo: state.mainReducer.isEditCardInfo,
            cardParams: state.mainReducer.cardParams,
            loading: state.mainReducer.loading,
            method: state.mainReducer.method,
            purchase: state.mainReducer.purchase,
            mobilePayStep: state.mainReducer.mobilePayStep,
            entry: state.mainReducer.entry,
            creditsPayData: state.mainReducer.creditsPayData,
            dataInfo: state.mainReducer.dataInfo,
        };
    });

    const searchParams = useMemo(() => {
        const url = window.location.search;
        return new URLSearchParams(url);
    }, []);

    const updateStripe = async (isNeedEalog = true) => {
        try {
            if (window.fj && window.fj.exchangeRate.stripe_version) {
                //
            } else {
                const { code, data } = await FJNetworkStore.getPaymentStatus();
                if (code === 200) {
                    if (window.fj && window.fj.exchangeRate) {
                        window.fj.exchangeRate = {
                            ...window.fj.exchangeRate,
                            ...data,
                        };
                    }
                }
            }

            // 设置用户是否使用新的stripe支付方式
            const { stripe_version } = window.fj.exchangeRate;
            // 已经存在付款行为的用户，不进行AB测试
            if (stripe_version?.all?.current !== 0) {
                if (stripe_version?.all?.current < 0) {
                    FJUtil.setIsNewPay(false);
                } else {
                    FJUtil.setIsNewPay(true);
                }
            } else {
                FJUtil.setIsNewPay(true);
            }

            // 欧元ab测试埋点
            if (isNeedEalog) {
                FJUtil.ealog('pricing page visit', {
                    'page type': method === 'popup' ? 'window' : 'web',
                });
            }
        } catch (e) {}
    };
    // 这个方法运行时机是后端关闭paypal窗口后，窗口关闭后，就能知道当前paypal订单支付状态，根据支付状态做相应界面操作
    window.getCrediteSubscribeId = (subscriptionID, boolean, code) => {
        const { info: currentCheckedCreditPackage } = creditsPayData;
        let total = 0;
        let beginTime = Date.now();

        let _checkSubscription = () => {
            dispatch(mainAction.changeLoadingStatusAction(true));

            // 返回paypal支付订单id，状态成功后，执行界面操作，不成功，又执行相应操作
            window.FJGlobalariable.modSubscription.network
                .checkPaypalCreditsSubscriptionStatus(subscriptionID)
                .then(res => {
                    // code 200 成功 400 支付中 500 参数错误
                    if (res.code === 200) {
                        const payInfo = res.data && res.data.credits instanceof Object ? res.data.credits : {};
                        dispatch(
                            mainAction.setDataInfo({
                                amount: payInfo.price,
                                period_end: payInfo.period_end,
                                transaction_id: payInfo.transaction_id,
                            }),
                        );
                        dispatch(mainAction.changeLoadingStatusAction(false));
                        // 打开支付成功的问卷页面
                        if (FJUtil.isMobile()) {
                            dispatch(FJMobilePayAction.setMobilePayStep(FJViewConstant.PAY_SUCCESS));
                            dispatch(FJMobilePayAction.updateCreditsNumAction());
                        } else {
                            dispatch(mainAction.changeBuyCreditsSuccessPage(true));
                        }

                        // 这里配合相关页面以及login-button等更新积分
                        if (window.fj && window.fj.event && window.fj.event.dispatch) {
                            window.fj.event.dispatch('UPDATE_USER_CREDITS');
                        }
                        FJUtil.ealog('ai credits revenue', {
                            'credit type':
                                currentCheckedCreditPackage.base +
                                (currentCheckedCreditPackage.present ? `+${currentCheckedCreditPackage.present}` : ''),
                            'coupon code off':
                                dataInfo.appliedCoupon &&
                                (dataInfo.couponObject.amount_off || dataInfo.couponObject.percent_off),
                            platform: 'paypal',
                            'coupon code': dataInfo.appliedCoupon,
                            location: cityCodeRef.current,
                            price: payInfo.price,
                            'ai tools': entry,
                        });
                    }

                    if (res.code === 400) {
                        let tryAgain = true;
                        total += 1;
                        if (total > 30 && Date.now() > beginTime + 30 * 1000) {
                            tryAgain = false;
                        }
                        if (tryAgain) {
                            setTimeout(() => {
                                _checkSubscription();
                            }, 1000);
                        }
                    }
                })
                .catch(err => {
                    let tryAgain = true;
                    if (err.type === 'ajax') {
                        total += 1;
                        if (total > 30 && Date.now() > beginTime + 30 * 1000) {
                            tryAgain = false;
                        }
                    }
                    if (tryAgain) {
                        _checkSubscription(500);
                    } else {
                        FJMessageStore.addMessage(FJLocalStore._('CONNECT_NETWORK_FAILED'), 'error');
                        // window.FJGlobalariable.modSubscription.showLoading = false;
                        dispatch(mainAction.changeLoadingStatusAction(false));
                    }
                });
        };
        if (boolean) {
            _checkSubscription();
        } else {
            FJUtil.checkLogin(code);
        }
    };
    useEffect(() => {
        _getCityCode();
    }, []);
    let _getCityCode = () => {
        try {
            FJNetworkStore.getUserCountryCode().then(countryCode => {
                cityCodeRef.current = countryCode;
            });
        } catch (e) {}
    };

    useEffect(() => {
        if (show && f_isFirst) {
            updateStripe();
            f_isFirst = false;
        }
    }, [show, f_isFirst, updateStripe]);

    useEffect(() => {
        // 根据url参数，确定要打开哪个面板
        const paramValue = searchParams.get('panelName');
        paramValue && dispatch(mainAction.changeTopPanel(paramValue));
    }, [dispatch, searchParams]);

    useEffect(() => {
        const packageName = searchParams.get('packageName');
        if (packageName) {
            const packageNameArr = purchase.map(item => item.packageName);
            const index = packageNameArr.indexOf(packageName);
            if (index > -1) {
                dispatch(
                    mainAction.changeCheckedCredits({
                        index,
                        info: purchase[index],
                    }),
                );
            }
        }
    }, [dispatch, purchase, searchParams]);

    // 初始化用户国家、汇率
    useEffect(() => {
        // 判断是否从首页跳转过来的，则保持首页包年、包月的状态
        const isAnnually = sessionStorage.getItem('jpHomePlanStatus');
        if (isAnnually) {
            dispatch(mainAction.updateIsAnnually(isAnnually !== 'false'));
            sessionStorage.removeItem('jpHomePlanStatus');
        }

        if (window.fj && window.fj.exchangeRate) {
            let tempExchangeRate = { ...FJConfig.USAExchangeRate };

            try {
                if (window.fj.exchangeRate.should_use_currency.can_ab_test) {
                    //
                    //
                } else {
                    if (window.fj.exchangeRate.should_use_currency.code === 'USD') {
                        //
                    } else {
                        tempExchangeRate = {
                            ...window.fj.exchangeRate,
                            currency_code: window.fj.exchangeRate.should_use_currency.code,
                            currency_symbol: window.fj.exchangeRate.should_use_currency.symbol,
                            rate:
                                window.fj.exchangeRate.should_use_currency.code === 'EUR'
                                    ? 1
                                    : window.fj.exchangeRate.rate,
                        };
                    }
                }
            } catch (e) {
                if (window.fj.exchangeRate?.area_code === 'IN') {
                    tempExchangeRate = { ...window.fj.exchangeRate };
                } else {
                    //
                }
            }

            const { area_code, currency_code, currency_symbol, rate } = tempExchangeRate;
            if (area_code && currency_code && currency_symbol && rate && parseFloat(rate)) {
                dispatch(mainAction.setCurrentCountry(area_code));
                dispatch(mainAction.setCurrentCountryMonetaryISO(currency_code));
                dispatch(mainAction.setCurrentCountryMonetary(currency_symbol));
                dispatch(mainAction.setExchangeRate(parseFloat(rate)));
            }
        }

        if (window?.fjuser?.eventType) {
            // 监听登录事件
            window.fjuser.addListener(window.fjuser.eventType.login, (result, json) => {
                if (result) {
                    updateStripe(false);
                }
            });
        }
    }, []);

    useEffect(() => {
        // 弹窗切换到积分选择页面
        if (method === 'popup') {
            dispatch(FJMobilePayAction.setMobilePayStep(FJViewConstant.CREDITS_CENTER));
        }
    }, [method, dispatch]);
    // 移动端渲染积分购买页
    const isRenderSingle = method !== 'popup' && mobilePayStep === FJViewConstant.NONE;
    // 移动端不渲染
    const isRenderPopup = method === 'popup' && !isMobile;
    return (
        <>
            {show && (
                <>
                    <Script
                        url='https://js.stripe.com/v2/'
                        strategy='beforeInteractive'
                        onError={handleScriptErrorStripe}
                        onLoad={handleScriptLoadStripe}
                    />
                    {isRenderPopup && <PopupPage />}
                    {isRenderSingle && <SinglePage />}
                    {isMobile && <FJMobile />}
                    {/* 以下为弹窗 */}
                    {loading && <LoadingBrickPopup />}
                    {isEditCardInfo && <EditCardInfoPopup cardParams={cardParams} />}
                </>
            )}
            {isEditCardInfo && cardParams && (
                <>
                    <Script
                        url='https://js.stripe.com/v2/'
                        strategy='beforeInteractive'
                        onError={handleScriptErrorStripe}
                        onLoad={handleScriptLoadStripe}
                    />
                    {loading && <LoadingBrickPopup />}
                    <EditCardInfoPopup cardParams={cardParams} />
                </>
            )}
        </>
    );
};
export default AppContent;
