import { useState, useEffect } from 'react';
import FJLocalStore from './store/FJLocalStore';
import store from './reducer/reducer/FJRoot';
import { Provider } from 'react-redux';
import AppContent from './AppContent';
import useLocalStore from './hooks/useLocalStore';
import mainAction from './reducer/action/mainAction';
// TODO 本地测试需要引入 测试服和正式服需要屏蔽
// import './config/flexclip.config';
import './jquery.payment.min';
import FJUtil from './util/FJUtil';

function App() {
    const [isLoading, setIsLoading] = useState(true);
    useLocalStore();
    useEffect(() => {
        FJLocalStore.initLanguage(() => {
            setIsLoading(false);
        });
    }, []);

    if (isLoading) {
        return null;
    }
    return (
        <Provider store={store}>
            <AppContent />
        </Provider>
    );
}

export default App;

window.openSubscription = (entry, page = 'plans', isAnnual = true, selectPlanIndex = 1) => {
    window.FJGlobalariable.modSubscription.network
        .getSubscription()
        .then(response => {
            // window.FJGlobalariable.modSubscription.updateSubscriptionInfo(response.data.subscription);
            let subscription = response.data.subscription;
            store.dispatch(
                mainAction.setCurrentPlan({
                    package: subscription.package,
                    period: subscription.period,
                    price: subscription.price,
                    period_end: subscription.period_end,
                    status: subscription.status,
                    sub_type: subscription.sub_type ? subscription.sub_type : 'stripe',
                }),
            );

            // 终身版用户跳转到订阅页面时，直接显示积分页面，需要将订阅、积分面板的按钮进行隐藏
            if (page !== 'plans' && subscription.period === 'lifetime') {
                store.dispatch(mainAction.changeTopPanel('Credits'));
            }

            const teamSubscription = response.data.teamSubscription;
            if (teamSubscription) {
                store.dispatch(mainAction.setTeamSubscription({ ...teamSubscription }));
                // 打开支付页面时，显示的席位数量不能小于最小席位数3 且不能低于已经订阅的团队套餐席位数，因此取最大值
                let seatNumber = 3;
                const { members = 0, seats = 0 } = teamSubscription;
                seatNumber = Math.max(members, seats, seatNumber);
                store.dispatch(mainAction.updateSeatCount(seatNumber));
            }
            if (store.getState().mainReducer.planType === 'team') {
                store.dispatch(
                    mainAction.changePayMethodAction(
                        teamSubscription.sub_type && teamSubscription.sub_type !== 'exch'
                            ? teamSubscription?.sub_type
                            : 'stripe',
                    ),
                );
            } else {
                store.dispatch(
                    mainAction.changePayMethodAction(
                        subscription.sub_type && subscription.sub_type !== 'exch' ? subscription.sub_type : 'stripe',
                    ),
                );
            }
        })
        .catch(error => {})
        .finally(() => {});

    // 初始化state
    window.FJGlobalariable.modSubscription.state.page = page;
    // window.FJGlobalariable.modSubscription.state.hideBackBtn = page !== 'plans';
    // window.FJGlobalariable.modSubscription.state.isAnnual = isAnnual;
    window.isAnnually = isAnnual;
    window.FJGlobalariable.modSubscription.entry = entry;
    // window.FJGlobalariable.modSubscription.state.selectPlan = window.FJGlobalariable.modSubscription.planData[selectPlanIndex];
    let obj = {
        entry: entry,
    };

    // 兼容老页面
    if (typeof entry === 'string') {
        if (entry === 'pricing') {
            obj.method = 'single';
        } else {
            obj.method = 'popup';
        }
        obj.panelName = 'Subscription';
        obj.planType = 'personal';
        obj.showCreditRuleTable = true; // pricing页兼容图生图不显示部分问卷和积分表的需求
        obj.showQuestionnaire = true;
    } else {
        const { minSeatCount, maxSeatCount } = store.getState().mainReducer;
        obj = {
            entry: entry?.entry ?? 'default',
            method: entry?.method ?? 'popup',
            planType: entry?.planType ?? 'personal',
            additionalSeatCount: entry?.additionalSeatCount,
            panelName: entry?.panelName ?? 'Subscription',
            showCreditRuleTable: entry?.showCreditRuleTable ?? true,
            showQuestionnaire: entry?.showQuestionnaire ?? true,
            calculatorSeatCount: Math.min(
                Math.max(Number(entry?.calculatorSeatCount) || minSeatCount, minSeatCount),
                maxSeatCount,
            ),
            useNewCreditModal: entry?.useNewCreditModal ?? false,
            useNewCreditModalABtest: entry?.useNewCreditModalABtest ?? false
        };
        window.FJGlobalariable.modSubscription.entry = obj.entry;
    }
    FJUtil.zgEventLog('套餐选择页-访问', {
        页面: window.FJGlobalariable.modSubscription.entry === 'pricing' ? 'pricing页面' : '升级弹出面板',
    });

    FJUtil.ealog('regions pricing AB_plan page visit_2024-12', {
        page: obj.method === 'single' ? 'pricing page' : 'editor pricing page',
        AB: window.uid ? (window.uid % 2 === 0 ? 'B' : 'A') : 'undefined'
    })

    const lastPlanType = store.getState().mainReducer.planType;
    store.dispatch(mainAction.openSubscribeView(obj));

    // 团队套餐暂不支持分区定价
    if (obj.planType === 'team') {
        // 如果之前应用了个人套餐的折扣码，切换到团队去除
        if (lastPlanType !== 'team') {
            store.dispatch(
                mainAction.setDataInfo({
                    appliedCoupon: null,
                }),
            );
        }
        return;
    }

    if (window.FJTier) {
        store.dispatch(mainAction.changeLoadingStatusAction(true));
        window.FJTier.check(true, () => {
            store.dispatch(mainAction.changeLoadingStatusAction(false));
            window.FJTier.addListener(data => {
                if (!data) return;
                // console.log(data)
                let list = data.list;
                let obj = {};
                obj.openDiscount = true;
                obj.lists = [];
                for (let key in list) {
                    if (list.hasOwnProperty(key)) {
                        obj.lists.push(list[key]);
                    }
                }
                store.dispatch(mainAction.setDiscountConfAction(obj));
                window.FJTier.apply();
            });
        });

        // window.FJTier.check()
    }
};

window.changeLanguageSubscribe = language => {
    FJLocalStore.switchLanguage(language);
};

window.openChangeCardModal = (title, shouldHideLoading, callback = () => {}, teamId) => {
    store.dispatch(
        mainAction.openEditCardInfoPopup({
            title: title,
            shouldHideLoading: shouldHideLoading,
            callback: callback,
            teamId,
        }),
    );
};
