import FJViewConstant from '../constant/FJViewConstant';
const AI_LIMIT = {};
AI_LIMIT[FJViewConstant.SUBTITLE_LIMIT] = {
    free: 5,
    basic: 30,
    plus: 60,
    business: 240,
    team: 240,
};
AI_LIMIT[FJViewConstant.AI_IMAGE_LIMIT] = {
    free: 5,
    basic: 50,
    plus: 200,
    business: 500,
    team: 500,
};
AI_LIMIT[FJViewConstant.AI_SCRIPT_LIMIT] = {
    free: 5,
    basic: 100,
    plus: 200,
    business: 500,
    team: 500,
};
AI_LIMIT[FJViewConstant.AI_VIDEO_LIMIT] = {
    free: 5,
    basic: 100,
    plus: 200,
    business: 500,
    team: 500,
};
AI_LIMIT[FJViewConstant.TEXT_TO_SPEECH_LIMIT] = {
    free: 1000,
    basic: 10000,
    plus: 50000,
    business: 300000,
    team: 300000,
};
AI_LIMIT[FJViewConstant.REMOVE_BACKGROUND_LIMIT] = {
    free: 3,
    basic: 50,
    plus: 100,
    business: 500,
    team: 500,
};
AI_LIMIT[FJViewConstant.TRANSLATE_LIMIT] = {
    free: 1000,
    basic: 10000,
    plus: 50000,
    business: 300000,
    team: 300000,
};
AI_LIMIT[FJViewConstant.AUDIO_REDUCE_LIMIT] = {
    free: 5,
    basic: 30,
    plus: 60,
    business: 240,
    team: 240,
};
AI_LIMIT[FJViewConstant.VOCAL_REMOVER_LIMIT] = {
    free: 5,
    basic: 30,
    plus: 60,
    business: 240,
    team: 240,
};
const PersonPlanList = [
    {
        totalTitle: 'video',
        type: FJViewConstant.TITLE,
    },
    {
        title: 'COMPARE_TD_1',
        titleDesc: 'COMPARE_TD_1_DESC',
        descFree: '720P',
        descPlus: '1080P',
        descBusiness: '4K',
    },
    {
        title: 'COMPARE_TD_4',
        titleDesc: 'COMPARE_TD_4_DESC',
        descFree: 'COMPARE_TD_4_1',
        descPlus: 'COMPARE_TD_4_3',
        descBusiness: 'COMPARE_TD_4_4',
    },
    {
        title: 'COMPARE_TD_12',
        titleDesc: 'COMPARE_TD_12_DESC',
        descFree: 'COMPARE_TD_12_1',
        descPlus: 'COMPARE_TD_12_3',
        descBusiness: 'COMPARE_TD_12_4',
    },
    {
        title: 'COMPARE_TD_2',
        titleDesc: 'COMPARE_TD_2_DESC',
        descFree: 'COMPARE_TD_2_1',
        descPlus: 'COMPARE_TD_2_3',
        descBusiness: 'COMPARE_TD_2_4',
    },
    {
        title: 'COMPARE_TD_14',
        titleDesc: 'COMPARE_TD_14_DESC',
        descFree: 'COMPARE_TD_14_1',
        descPlus: 'COMPARE_TD_14_3',
        descBusiness: 'COMPARE_TD_14_4',
    },
    {
        title: 'COMPARE_TD_11',
        titleDesc: 'COMPARE_TD_11_DESC',
        descFree: 'COMPARE_TD_11_1',
        descPlus: 'COMPARE_TD_11_3',
        descBusiness: 'COMPARE_TD_11_4',
    },
    {
        title: 'COMPARE_TD_6',
        titleDesc: 'COMPARE_TD_6_DESC',
        descFree: 'COMPARE_TD_6_1',
        descPlus: 'COMPARE_TD_6_3',
        descBusiness: 'COMPARE_TD_6_4',
    },
    {
        totalTitle: 'ai-features',
        type: FJViewConstant.TITLE,
    },
    {
        title: 'COMPARE_TD_21',
        titleDesc: 'COMPARE_TD_21_DESC',
        descFree: 'COMPARE_TD_21_1',
        descPlus: 'COMPARE_TD_21_2',
        descBusiness: 'COMPARE_TD_21_3',
        type: FJViewConstant.AI,
    },
    {
        totalTitle: 'storage',
        type: FJViewConstant.TITLE,
    },
    {
        title: 'COMPARE_TD_8',
        titleDesc: 'COMPARE_TD_8_DESC',
        descFree: 'COMPARE_TD_8_1',
        descPlus: 'COMPARE_TD_8_3',
        descBusiness: 'COMPARE_TD_8_4',
    },
    {
        title: 'COMPARE_TD_7',
        titleDesc: 'COMPARE_TD_7_DESC',
        descFree: 'COMPARE_TD_7_1',
        descPlus: 'COMPARE_TD_7_3',
        descBusiness: 'COMPARE_TD_7_4',
    },
    {
        title: 'COMPARE_TD_3',
        titleDesc: 'COMPARE_TD_3_DESC',
        descFree: 'COMPARE_TD_3_1',
        descPlus: 'COMPARE_TD_3_3',
        descBusiness: 'COMPARE_TD_3_4',
    },
    {
        totalTitle: 'branding',
        type: FJViewConstant.TITLE,
    },
    {
        title: 'COMPARE_TD_13',
        titleDesc: 'COMPARE_TD_13_DESC',
        descFree: 'COMPARE_TD_13_1',
        descPlus: 'COMPARE_TD_13_3',
        descBusiness: 'COMPARE_TD_13_4',
    },
    {
        title: 'COMPARE_TD_5',
        titleDesc: 'COMPARE_TD_5_DESC',
        descFree: 'COMPARE_TD_5_1',
        descPlus: 'COMPARE_TD_5_3',
        descBusiness: 'COMPARE_TD_5_4',
    },
    {
        title: 'COMPARE_TD_9',
        titleDesc: 'COMPARE_TD_9_DESC',
        descFree: 'COMPARE_TD_9_1',
        descPlus: 'COMPARE_TD_9_3',
        descBusiness: 'COMPARE_TD_9_4',
    },
];

const TeamPlanList = [
    {
        totalTitle: 'video',
        type: FJViewConstant.TITLE,
    },
    {
        title: 'COMPARE_TD_1',
        titleDesc: 'COMPARE_TD_1_DESC',
        descFree: '720P',
        descPlus: '1080P',
        descBusiness: '4K',
    },
    {
        title: 'COMPARE_TD_4',
        titleDesc: 'COMPARE_TD_4_DESC',
        descFree: 'COMPARE_TD_4_1',
        descPlus: 'COMPARE_TD_4_3',
        descBusiness: 'COMPARE_TD_4_4',
    },
    {
        title: 'COMPARE_TD_12',
        titleDesc: 'COMPARE_TD_12_DESC',
        descFree: 'COMPARE_TD_12_1',
        descPlus: 'COMPARE_TD_12_3',
        descBusiness: 'COMPARE_TD_12_4',
    },
    {
        title: 'COMPARE_TD_2',
        titleDesc: 'COMPARE_TD_2_DESC',
        descFree: 'COMPARE_TD_2_1',
        descPlus: 'COMPARE_TD_2_3',
        descBusiness: 'COMPARE_TD_2_4',
    },
    {
        title: 'COMPARE_TD_14',
        titleDesc: 'COMPARE_TD_14_DESC',
        descFree: 'COMPARE_TD_14_1',
        descPlus: 'COMPARE_TD_14_3',
        descBusiness: 'COMPARE_TD_14_4',
    },
    {
        title: 'COMPARE_TD_11',
        titleDesc: 'COMPARE_TD_11_DESC',
        descFree: 'COMPARE_TD_11_1',
        descPlus: 'COMPARE_TD_11_3',
        descBusiness: 'COMPARE_TD_11_4',
    },
    {
        title: 'COMPARE_TD_6',
        titleDesc: 'COMPARE_TD_6_DESC',
        descFree: 'COMPARE_TD_6_1',
        descPlus: 'COMPARE_TD_6_3',
        descBusiness: 'COMPARE_TD_6_4',
    },
    {
        totalTitle: 'ai-features',
        type: FJViewConstant.TITLE,
    },
    {
        title: 'TEAM_COMPARE_TD_21',
        titleDesc: 'TEAM_COMPARE_TD_21_DESC',
        descFree: 'TEAM_COMPARE_TD_21_1',
        descPlus: 'TEAM_COMPARE_TD_21_2',
        descBusiness: 'TEAM_COMPARE_TD_21_3',
        type: FJViewConstant.AI,
    },
    {
        totalTitle: 'storage',
        type: FJViewConstant.TITLE,
    },
    {
        title: 'TEAM_COMPARE_TD_8',
        titleDesc: 'TEAM_COMPARE_TD_8_DESC',
        descFree: 'TEAM_COMPARE_TD_8_1',
        descBusiness: 'TEAM_COMPARE_TD_8_4',
    },
    {
        title: 'TEAM_COMPARE_TD_7',
        titleDesc: 'TEAM_COMPARE_TD_7_DESC',
        descFree: 'TEAM_COMPARE_TD_7_1',
        descBusiness: 'TEAM_COMPARE_TD_7_4',
    },
    {
        title: 'TEAM_COMPARE_TD_3',
        titleDesc: 'TEAM_COMPARE_TD_3_DESC',
        descFree: 'TEAM_COMPARE_TD_3_1',
        descBusiness: 'TEAM_COMPARE_TD_3_4',
    },
    {
        totalTitle: 'branding-collaboration',
        type: FJViewConstant.TITLE,
    },
    {
        title: 'TEAM_COMPARE_TD_13',
        titleDesc: 'TEAM_COMPARE_TD_13_DESC',
        descFree: 'TEAM_COMPARE_TD_13_1',
        descBusiness: 'TEAM_COMPARE_TD_13_4',
    },
    {
        title: 'TEAM_COMPARE_TD_5',
        titleDesc: 'TEAM_COMPARE_TD_5_DESC',
        descFree: 'TEAM_COMPARE_TD_5_1',
        descBusiness: 'TEAM_COMPARE_TD_5_4',
    },
    {
        title: 'TEAM_COMPARE_TD_9',
        titleDesc: 'TEAM_COMPARE_TD_9_DESC',
        descFree: 'TEAM_COMPARE_TD_9_1',
        descBusiness: 'TEAM_COMPARE_TD_9_4',
    },
    {
        title: 'TEAM_COMPARE_TD_16',
        titleDesc: 'TEAM_COMPARE_TD_16_DESC',
        descFree: 'TEAM_COMPARE_TD_16_1',
        descBusiness: 'TEAM_COMPARE_TD_16_4',
    },
    {
        title: 'TEAM_COMPARE_TD_17',
        titleDesc: 'TEAM_COMPARE_TD_17_DESC',
        descFree: 'TEAM_COMPARE_TD_17_1',
        descBusiness: 'TEAM_COMPARE_TD_17_4',
    },
    {
        title: 'TEAM_COMPARE_TD_18',
        titleDesc: 'TEAM_COMPARE_TD_18_DESC',
        descFree: 'TEAM_COMPARE_TD_18_1',
        descBusiness: 'TEAM_COMPARE_TD_18_4',
    },
    {
        title: 'TEAM_COMPARE_TD_19',
        titleDesc: 'TEAM_COMPARE_TD_19_DESC',
        descFree: 'TEAM_COMPARE_TD_19_1',
        descBusiness: 'TEAM_COMPARE_TD_19_4',
    },
];

export { PersonPlanList, TeamPlanList, AI_LIMIT };
