import React, { useMemo } from 'react';
import ABTestBoxA from './ABTestBoxA';
import ABTestBoxB from './ABTestBoxB';

export default function ABTestBoxContainer(props) {
    const { purchase, checkedPurchase, purchaseItemClick, baseUrl, chooseCreditsAndToPayPanel } = props;
    const shouldRenderA = useMemo(() => {
        return Math.random() < 0.5;
    }, []);

    const _renderBox = () => {
        return shouldRenderA ? (
            <ABTestBoxA
                purchase={purchase}
                checkedPurchase={checkedPurchase}
                purchaseItemClick={purchaseItemClick}
                baseUrl={baseUrl}
                chooseCreditsAndToPayPanel={chooseCreditsAndToPayPanel}
            />
        ) : (
            <ABTestBoxB
                purchase={purchase}
                checkedPurchase={checkedPurchase}
                purchaseItemClick={purchaseItemClick}
                baseUrl={baseUrl}
                chooseCreditsAndToPayPanel={chooseCreditsAndToPayPanel}
            />
        );
    };

    return _renderBox();
}
