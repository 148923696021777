import Style from './CouponBrick.module.less';
import FJLocalStore from '../../store/FJLocalStore';
import { useCallback, useEffect, useMemo, useRef, useState, useImperativeHandle, forwardRef } from 'react';
import classNames from 'classnames';
import FJButton from '../common/FJButton/FJButton';
import { useDispatch, useSelector } from 'react-redux';
import mainAction from '../../reducer/action/mainAction';
import FJMessageStore from '../../store/FJMessageStore';
import FJUtil from '../../util/FJUtil';
import { useINOneTimePayment } from '../../hooks/useINOneTimePayment';
import { useAmount } from '../../hooks/useAmount';

let CouponBrick = (props, ref) => {
    let { isAnnually, plan, active } = props;

    let [hasInput, setHasInput] = useState(false);
    let [loading, setLoading] = useState(false);

    // 是否手动输入
    let [hasManualInput, setHasManualInput] = useState(false);

    let inputRef = useRef(null);
    let dispatch = useDispatch();
    let {
        selectPage,
        dataInfo,
        discountConf,
        seatCount,
        additionalSeatCount,
        planType,
        currentPlan,
        teamSubscription,
    } = useSelector(state => {
        return {
            selectPage: state.mainReducer.selectPage,
            dataInfo: state.mainReducer.dataInfo,
            discountConf: state.mainReducer.discountConf,
            seatCount: state.mainReducer.seatCount,
            additionalSeatCount: state.mainReducer.additionalSeatCount,
            planType: state.mainReducer.planType,
            currentPlan: state.mainReducer.currentPlan,
            teamSubscription: state.mainReducer.teamSubscription,
        };
    });

    useImperativeHandle(ref, () => {
        return {
            getRealPrice: _realPrice,
        };
    });

    const currentCountry = useSelector(state => state.mainReducer.currentCountry);

    // 当前用户所在地区货币符号
    const currentCountryMonetary = useSelector(state => state.mainReducer.currentCountryMonetary);
    const currentCountryMonetaryISO = useSelector(state => state.mainReducer.currentCountryMonetaryISO);
    const exchangeRate = useSelector(state => state.mainReducer.exchangeRate);

    const teamId = planType === 'team' ? teamSubscription?.id : null;
    let isUpgrade = useCallback(() => {
        if (planType === 'team') {
            return teamSubscription && teamSubscription.package !== 'free';
        }
        return currentPlan.package !== 'free';
    }, [currentPlan.package, planType, teamSubscription]);

    let getPlan = useCallback(() => {
        let selectPeriod = isAnnually ? 'annual' : 'monthly';
        let _page = selectPage === 'free' ? 'basic' : selectPage;
        if (planType === 'team') {
            _page = 'team_business';
        }
        return _page + '_' + selectPeriod;
    }, [isAnnually, planType, selectPage]);

    useEffect(() => {
        let coupon = '';
        if (active && discountConf.openDiscount) {
            let lists = discountConf.lists;
            if (!lists) return;
            for (const item of lists) {
                if (!item.package) {
                    coupon = item.coupon;
                }
                if (item.package === selectPage) {
                    if (isAnnually) {
                        coupon = item.coupon;
                    }
                }
            }
        } else {
            coupon = FJUtil.getCookie('coupon');
        }
        if (coupon) {
            let plan = getPlan();
            dispatch(mainAction.changeLoadingStatusAction(true));
            window.FJGlobalariable.modSubscription.network
                .redeemCoupon(coupon, plan, isUpgrade(), teamId, currentCountryMonetaryISO)
                .then(res => {
                    switch (res.code) {
                        case 200:
                            if (res.data.allow_coupon) {
                                inputRef.current.value = coupon;
                                window.FJGlobalariable.modSubscription.state.appliedCoupon = coupon;
                                dispatch(
                                    mainAction.setDataInfo({
                                        appliedCoupon: coupon,
                                        coupon: coupon,
                                        couponObject: res.data.coupon_object,
                                        estimateAmount: res.data,
                                        freeOfCharge: res.data.free_of_charge,
                                    }),
                                );
                            }
                            break;
                        case 214:
                            FJUtil.checkLogin(res.code);
                            // FJMessageStore.addMessage(FJLocalStore._('PAGE_EXPIRED'), 'warning');
                            break;
                        case 335:
                            FJUtil.checkLogin(res.code);
                            break;
                    }
                })
                .catch(err => {
                    console.log(err);
                    // dispatch(mainAction.changeLoadingStatusAction(false));
                })
                .finally(() => {
                    window.FJGlobalariable.modSubscription.showLoading = false;
                    dispatch(mainAction.changeLoadingStatusAction(false));
                });
        } else {
            dispatch(
                mainAction.setDataInfo({
                    appliedCoupon: null,
                }),
            );
        }
    }, [discountConf, selectPage, isAnnually, active, getPlan, dispatch, isUpgrade, teamId, currentCountryMonetaryISO]);

    let _onClick = () => {
        setHasInput(!hasInput);
    };
    let _onChange = event => {
        let value = event.target.value;
        if (!/^(|[a-zA-Z0-9_]{0,50})$/.test(value)) {
            event.target.value = value.substr(0, 50);
        }
    };

    let redeemCoupon = callback => {
        // if (this.isRedeemButtonDisabled) {
        //     return;
        // }
        // let coupon = dataInfo.coupon;
        let coupon = inputRef.current.value;
        let plan = getPlan();

        // this.isRedeeming = true;
        dispatch(mainAction.changeLoadingStatusAction(true));
        setLoading(true);
        window.FJGlobalariable.modSubscription.network
            .redeemCoupon(coupon, plan, isUpgrade(), teamId, currentCountryMonetaryISO)
            .then(res => {
                switch (res.code) {
                    case 200:
                        if (res.data.allow_coupon) {
                            setHasInput(false); // 关闭优惠码输入窗口
                            // 展示预估价格
                            window.FJGlobalariable.modSubscription.state.appliedCoupon = coupon;
                            dispatch(
                                mainAction.setDataInfo({
                                    appliedCoupon: coupon, // 设置应用后的coupon
                                    coupon: coupon,
                                    couponObject: res.data.coupon_object,
                                    estimateAmount: res.data,
                                    freeOfCharge: res.data.free_of_charge,
                                }),
                            );
                        } else {
                            // 不可使用coupon
                            // dataInfo.appliedCoupon = null;
                            dispatch(
                                mainAction.setDataInfo({
                                    appliedCoupon: null,
                                }),
                            );
                            FJMessageStore.addMessage(FJLocalStore._('COM_FJ_SP_COUPON_CONTINUOUS'), 'warning');
                        }
                        break;
                    case 214:
                        FJUtil.checkLogin(res.code);
                        // FJMessageStore.addMessage(FJLocalStore._('PAGE_EXPIRED'), 'warning');
                        break;
                    case 335:
                        FJUtil.checkLogin(res.code);
                        break;
                    default:
                        // dataInfo.appliedCoupon = null;
                        dispatch(
                            mainAction.setDataInfo({
                                appliedCoupon: null,
                            }),
                        );
                        if (res.msg === 'SUB.COM_FJ_SP_COUPON_MATCH_PLAN_ERROR') {
                            let list = res.data.split(',');
                            list = list.map(item => {
                                // 将拆分之后的元素筛除掉''
                                let arr = item.split(' ').filter(splitItem => splitItem.length > 0);
                                arr = arr.map(arrItem => {
                                    // 将字符串前后的空格删除之后去匹配本地化
                                    return FJLocalStore._(arrItem.trim().toUpperCase());
                                });
                                return arr.join('');
                            });
                            let MSG = res.msg.replace('SUB.', '');

                            if (window.text_printf) {
                                FJMessageStore.addMessage(
                                    window.text_printf(FJLocalStore._(MSG), list.join(', ')),
                                    'warning',
                                );
                            }
                        } else if (res.msg === 'SUB.CANNOT_USED_FOR_UPGRADE') {
                            FJMessageStore.addMessage(FJLocalStore._('CANNOT_USED_FOR_UPGRADE'), 'warning');
                        } else if (res.msg === 'SUB.COM_FJ_SP_COUPON_NOT_ENOUGH_CURRENCY') {
                            FJMessageStore.addMessage(FJLocalStore._('the-code-only-applies-to-payments'), 'error');
                        } else {
                            let MSG = res.msg.replace('SUB.', '');
                            if (window.text_) {
                                FJMessageStore.addMessage(window.text_(FJLocalStore._(MSG)), 'warning');
                            }
                        }
                }
            })
            .catch(err => {
                // dataInfo.appliedCoupon = null;
                dispatch(
                    mainAction.setDataInfo({
                        appliedCoupon: null,
                    }),
                );
                // FJMessageStore.addMessage(FJLocalStore._('COM_FJ_SP_COUPON_NOT_EXIST'), 'warning');
                console.log(err);
            })
            .finally(() => {
                // this.isRedeeming = false;
                setLoading(false);
                if (typeof callback === 'function') {
                    callback();
                }
                dispatch(mainAction.changeLoadingStatusAction(false));
            });
    };
    let _submit = () => {
        // let value = inputRef.current.value;
        // 设置切换时不自动更新Coupon
        setHasManualInput(!hasManualInput);
        redeemCoupon();
    };
    let _autoPayPrice = useCallback(
        shouldToFixed => {
            let price;
            if (plan.package === 'team') {
                if (additionalSeatCount) {
                    price = isAnnually
                        ? additionalSeatCount * plan.seatPrice.annual * 12
                        : additionalSeatCount * plan.seatPrice.monthly;
                } else {
                    price = isAnnually
                        ? plan.price.annual * 12 + (seatCount - 3) * plan.seatPrice.annual * 12
                        : plan.price.monthly + (seatCount - 3) * plan.seatPrice.monthly;
                }
            } else {
                price = isAnnually ? plan.price.annual * 12 : plan.price.monthly;
            }
            return shouldToFixed ? price.toFixed(2) : price;
        },
        [plan, isAnnually, seatCount, additionalSeatCount],
    );

    let _realPrice = useCallback(
        shouldToFixed => {
            if (dataInfo.appliedCoupon && dataInfo.couponObject) {
                let amount = _autoPayPrice(shouldToFixed);
                if (dataInfo.couponObject.amount_off) {
                    amount = amount - dataInfo.couponObject.amount_off;
                    if (shouldToFixed) {
                        amount = amount.toFixed(2);
                    }
                } else if (dataInfo.couponObject.percent_off) {
                    amount = (1 - parseFloat(dataInfo.couponObject.percent_off) / 100) * amount;
                    if (shouldToFixed) {
                        amount = amount.toFixed(2);
                    }
                }

                if (amount < 0) amount = 0;
                return amount;
            } else {
                return _autoPayPrice(shouldToFixed);
            }
        },
        [_autoPayPrice, dataInfo],
    );

    const periodTime = useMemo(() => {
        return FJUtil.formatTimeZone(
            isAnnually ? FJUtil.getNextYearSameDay(new Date()) : FJUtil.getNextMonthSameDay(new Date()),
        );
    }, [isAnnually]);

    const payMessage = useMemo(() => {
        if (additionalSeatCount) {
            let text = FJLocalStore._('new-team-members-you-invite-will-be-billed-at');
            text = text.replace('xxx', `${currentCountryMonetary}${currentCountryMonetaryISO}${_realPrice(true)}`);
            return text;
        }

        let text = (isAnnually ? FJLocalStore._('YOU_WILL_CHARGED_YEAR') : FJLocalStore._('YOU_WILL_CHARGED')).replace(
            'xxx',
            `${currentCountryMonetary}${currentCountryMonetaryISO}${_autoPayPrice(true)}`,
        );

        if (currentCountry === 'US' || currentCountryMonetaryISO === 'EUR') {
            let index = -1;
            switch (FJLocalStore.currentTag()) {
                case 'cn':
                case 'tw':
                    index = text.lastIndexOf('，');
                    index !== -1 && (text = text.slice(0, index) + '。');
                    break;
                case 'jp':
                    index = text.indexOf('。');
                    index !== -1 && (text = text.slice(0, index + 1));
                    break;
                default: {
                    const arr = text.split('.');
                    const sliceArr = arr.slice(0, arr.length - 2);
                    text = sliceArr.join('.') + '.';
                }
            }
        }
        return text;
    }, [
        _autoPayPrice,
        additionalSeatCount,
        currentCountry,
        isAnnually,
        _realPrice,
        currentCountryMonetary,
        currentCountryMonetaryISO,
    ]);

    const payINMessage = useMemo(() => {
        if (additionalSeatCount) {
            let text = FJLocalStore._('new-team-members-you invite-will-be-billed');
            text = text.replace('xxx', `$USD${_realPrice(true)}`);
            text = text.replace('xxx', periodTime);
            return text;
        }
        let text = FJLocalStore._('NOT_RENEWED_AND_EXPIRE_TIME');
        text = text.replace('xxx', `$USD${_realPrice(true)}`);
        text = text.replace('xxx', periodTime);
        return text;
    }, [_realPrice, periodTime, additionalSeatCount]);

    const isInOneTimepayment = useINOneTimePayment();

    const amount = Math.floor(FJUtil.preciseMultiply([parseFloat(_realPrice(true)), 1]).toFixed(2) * 100);
    useAmount(amount);

    return (
        <>
            <div className={Style.total_billed_box}>
                <div>
                    <div className={Style.toolBilledTitle}>{FJLocalStore._('TOTAL_BILLED')}</div>
                    <span className={Style.currentCountryMonetary}>
                        {currentCountryMonetary || '$'}
                        {currentCountryMonetaryISO}
                    </span>
                    <span className={Style.curPrice}>
                        {FJUtil.formatNumberThousandth(
                            FJUtil.preciseMultiply([parseFloat(_realPrice(true)), exchangeRate]).toFixed(2),
                        )}
                    </span>
                    {dataInfo.appliedCoupon !== null && (
                        <span className={Style.oldPrice}>
                            {FJUtil.formatNumberThousandth(
                                FJUtil.preciseMultiply([parseFloat(_autoPayPrice(true)), exchangeRate]).toFixed(2),
                            )}
                        </span>
                    )}
                </div>
                <div className={Style.coupon_box}>
                    <span className={Style.coupon_entry} onClick={_onClick}>
                        {FJLocalStore._('HAVE_A_COUPON')}
                    </span>
                    <div className={classNames(Style.input_box, { [Style.input_box_show]: hasInput })}>
                        <input
                            className={Style.input}
                            ref={inputRef}
                            onChange={_onChange}
                            placeholder={FJLocalStore._('REDEEM_PLACEHOLDER')}
                        />
                        <FJButton
                            isLoading={loading}
                            className={Style.btn}
                            onClick={_submit}
                            text={FJLocalStore._('REDEEM')}
                        />
                    </div>
                </div>
            </div>
            {dataInfo.appliedCoupon !== null && (
                <span className={Style.tip}>{FJLocalStore._('REDEEM_SUCCESSFUL')}</span>
            )}
            {isInOneTimepayment ? (
                <div className={Style.pay_message}>{payINMessage}</div>
            ) : (
                <div className={Style.pay_message} dangerouslySetInnerHTML={{ __html: payMessage }} />
            )}
        </>
    );
};
export default forwardRef(CouponBrick);
