import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import mainAction from '../../../reducer/action/mainAction';
import Styles from './NewCredits.module.less';
import BaseBox from './BaseBox';
import ABTestBoxContainer from './ABTestBoxContainer';
import CloseButton from '../../common/CloseButton/CloseButton';

const baseUrl = 'https://resource.flexclip.com/app/subscription/img/';

export default function NewCredits(props) {
    const { purchase = [], getUserInfo = () => {}, changeCheckedCredits, useNewCreditModalABtest } = props;
    const dispatch = useDispatch();
    const creditsPayData = useSelector(state => state.mainReducer.creditsPayData);
    const checkedPurchase = creditsPayData.index;
    // const isTeamAccount = window.fjuser?.info?.space_type === 'team';

    const chooseCreditsAndToPayPanel = () => {
        if (typeof checkedPurchase !== 'number') {
            return;
        }
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        getUserInfo && getUserInfo();
    };

    const purchaseItemClick = index => {
        if (checkedPurchase === index) {
            return;
        }
        changeCheckedCredits(index);
    };

    const handleClose = () => {
        dispatch(mainAction.closeSubscribeView());
        dispatch(mainAction.openOrCloseCreditsPayPanel(false)); // 关闭积分购买面板
        dispatch(mainAction.changeBuyCreditsSuccessPage(false)); // 关闭问卷页面
    };

    return (
        <div className={Styles.container}>
            <div className={Styles.box}>
                {useNewCreditModalABtest ? (
                    <ABTestBoxContainer
                        purchase={purchase}
                        checkedPurchase={checkedPurchase}
                        purchaseItemClick={purchaseItemClick}
                        baseUrl={baseUrl}
                        chooseCreditsAndToPayPanel={chooseCreditsAndToPayPanel}
                    />
                ) : (
                    <BaseBox
                        purchase={purchase}
                        checkedPurchase={checkedPurchase}
                        purchaseItemClick={purchaseItemClick}
                        baseUrl={baseUrl}
                        chooseCreditsAndToPayPanel={chooseCreditsAndToPayPanel}
                    />
                )}
                <div className={Styles.btn} onClick={handleClose}>
                    <CloseButton onclick={close} />
                </div>
            </div>
        </div>
    );
}
