/**
 * Created by Carry on June/12/18.
 */
import FJUtil from '../util/FJUtil';
import FJEvent from '../lib/FJEvent';
import FJEventConstant from '../constant/FJEventConstant';

let _currentTag = 'en';
let _languages = {};
let callbacks = new Set();
let localStore = {
    _: function (key) {
        try {
            let val = this.language()[key];
            if (typeof val === 'undefined') val = _languages[_currentTag][key];
            if (typeof val === 'undefined') val = key;
            return val;
        } catch (e) {
            console.log(e);
            return key;
        }
    },
    initLanguage: function (callback) {
        let url = document.location.href;
        let tag = 'en';
        if (window.locale) {
            let code = window.locale.toLowerCase();
            if (code.indexOf('cn') !== -1) tag = 'cn';
            else if (code.indexOf('tw') !== -1) tag = 'tw';
            else if (code.indexOf('ja') !== -1) tag = 'jp';
            else if (code.indexOf('fr') !== -1) tag = 'fr';
            else if (code.indexOf('de') !== -1) tag = 'de';
            else if (code.indexOf('es') !== -1) tag = 'es';
            else if (code.indexOf('pt') !== -1) tag = 'pt';
            else if (code.indexOf('it') !== -1) tag = 'en';
            else if (code.indexOf('ar') !== -1) tag = 'ar';
        } else {
            if (url.indexOf('/cn/') !== -1) {
                tag = 'cn';
            } else if (url.indexOf('/tw/') !== -1) {
                tag = 'tw';
            } else if (url.indexOf('/de/') !== -1) {
                tag = 'de';
            } else if (url.indexOf('/es/') !== -1) {
                tag = 'es';
            } else if (url.indexOf('/fr/') !== -1) {
                tag = 'fr';
            } else if (url.indexOf('/pt/') !== -1) {
                tag = 'pt';
            } else if (url.indexOf('/jp/') !== -1) {
                tag = 'jp';
            } else if (url.indexOf('/it/') !== -1) {
                tag = 'en';
            } else if (url.indexOf('/ar/') !== -1) {
                tag = 'ar';
            }
        }
        this.switchLanguage(tag, callback);
    },
    switchLanguage: function (tag, callback) {
        _currentTag = tag;
        if (typeof _languages[tag] === 'undefined') {
            FJUtil.getLanguage(tag, data => {
                _languages[tag] = data;
                if (callback) callback({ state: 'success' });

                FJEvent.dispatch(FJEventConstant.LANGUAGE_CHANGED);
            });
        } else {
            if (callback) callback({ state: 'error' });
        }
    },

    getYouWantLanguage: function (tag, callback) {
        if (typeof _languages[tag] === 'undefined') {
            FJUtil.getLanguage(tag, data => {
                _languages[tag] = data;
                callback && callback(data);
            });
        } else {
            callback && callback(_languages[tag]);
        }
    },
    language: function () {
        return _languages[_currentTag];
    },
    currentTag: function () {
        return _currentTag;
    },
    subscribe: function (callback) {
        callbacks.add(callback);
        return () => {
            callbacks.delete(callback);
        };
    },
    getJumpUrl: function (url) {
        let tag = _currentTag === 'en' ? '' : '/' + _currentTag;
        let URL = tag + url;
        return URL;
    },
};
export default localStore;
