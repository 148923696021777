import React, { Fragment, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import mainAction from '../../../reducer/action/mainAction';
import Styles from './ABTestBoxB.module.less';
import InlineSvg from 'react-inlinesvg';
import TickIcon from '../../../icon/tick.svg';
import ChangeButton from '../../common/ChangeButton/ChangeButton';
import FJNetworkStore from '../../../store/FJNetworkStore';
import FJUtil from '../../../util/FJUtil';

const jumpToPricingPage = () => {
    const url = FJUtil.getCurrentUrl() + '/pricing.html';
    window.open(url, '_blank');
};

const CreditLeftPart = props => {
    const { purchase, checkedPurchase, purchaseItemClick, baseUrl, chooseCreditsAndToPayPanel } = props;

    return (
        <div className={Styles.credit_container}>
            {purchase.map((item, index) => {
                return (
                    <div
                        className={`${Styles.list_item} ${checkedPurchase === index ? Styles.active_item : ''}`}
                        key={'list' + index + 1}
                        onClick={() => {
                            purchaseItemClick(index);
                        }}
                    >
                        <div className={Styles.item_list}>
                            <div className={Styles.list_left_top}>
                                <img
                                    className={Styles.left_top_icon}
                                    width={20}
                                    height={20}
                                    src={`${baseUrl}credits.webp`}
                                />
                                <p className={Styles.left_top_credit}>{item.base}</p>
                            </div>
                            <p className={Styles.left_bottom}>${(item.price / item.base).toFixed(3)}USD/Credits</p>
                        </div>
                        <div className={Styles.item_right}>
                            <span>US$</span>
                            <span>{item.price}</span>
                        </div>
                    </div>
                );
            })}
            <button className={Styles.left_btn} onClick={chooseCreditsAndToPayPanel}>
                Choose Now
            </button>
            <p className={Styles.tip}>Credites validity: 365 days from date of purchase.</p>
        </div>
    );
};

const PlanItem = props => {
    const { item, isAnnually, currentItem, setCurrentItem } = props;

    return (
        <div
            className={`${Styles.plan_item} ${currentItem === item.package ? Styles.plan_item_active : ''}`}
            onClick={() => {
                if (currentItem === item.package) return;
                setCurrentItem(item.package);
            }}
        >
            <div className={Styles.price_box}>
                <div className={Styles.package}>{item.package}</div>
                <div className={Styles.price}>
                    <span className={Styles.currency}>US$</span>
                    <span className={Styles.number}>{isAnnually ? item.price.annual : item.price.monthly}</span>
                </div>
            </div>
            <p className={Styles.text}>
                <InlineSvg src={TickIcon} />
                <span>All Video Editor feature</span>
            </p>
            <p className={Styles.text}>
                <InlineSvg src={TickIcon} />
                <span>Plus AI creates 800 Credits/mo</span>
            </p>
            <p className={Styles.learn_more} onClick={jumpToPricingPage}>Learn More</p>
        </div>
    );
};

const SubscribeLeftPart = () => {
    const planData = useSelector(state => state.mainReducer.planData);
    const isAnnually = useSelector(state => state.mainReducer.isAnnually);
    const displayPlanData = planData.filter(plan => plan.package !== 'team' && plan.package !== 'free');
    const [currentItem, setCurrentItem] = useState('plus');
    const dispatch = useDispatch();

    let changePlan = value => {
        window.isAnnually = value;
        dispatch(mainAction.updateIsAnnually(value));
    };

    let changeStep = (stepNum, page) => {
        if (page) {
            window.selectPage = page;
            dispatch(mainAction.changeSelectPageAction(page));
        }
        dispatch(mainAction.changeStepAction(stepNum));
    };

    const choosePlanToPayPanel = () => {
        FJNetworkStore.getCardInfo().then(response => {
            let subscription =
                typeof response.data === 'object' ? (Array.isArray(response.data) ? {} : response.data) : null;

            if (!subscription) {
                return window.fjmessage && window.fjmessage.addMessage(response.msg || 'request err', 'error');
            }

            dispatch(
                mainAction.setCurrentPlan({
                    last4: subscription.ss_last4,
                }),
            );
            changeStep(2, currentItem);
        });
    };

    return (
        <div className={Styles.sub_container}>
            <div className={Styles.time_changer}>
                <span className={Styles.month}>Billed Monthly</span>
                <ChangeButton onClick={changePlan} value={isAnnually} />
                <div className={Styles.year}>Billed Annually</div>
            </div>
            <div className={Styles.content}>
                {displayPlanData.map((item, index) => {
                    return (
                        <Fragment key={'plan item' + index + 1}>
                            <PlanItem
                                item={item}
                                isAnnually={isAnnually}
                                currentItem={currentItem}
                                setCurrentItem={setCurrentItem}
                            />
                        </Fragment>
                    );
                })}
                <button onClick={choosePlanToPayPanel}>Choose Now</button>
            </div>
        </div>
    );
};

const SubscribeRightPart = props => {
    const { baseUrl } = props;

    return (
        <>
            <img src={`${baseUrl}new-credit-subscibe.webp`} alt='sub img' className={Styles.sub_img} />
            <div className={Styles.sub_content}>
                <p>FlexClip Video Editor Subscription</p>
                <button onClick={jumpToPricingPage}>Learn More</button>
            </div>
        </>
    );
};

export default function ABTestBoxB(props) {
    const { purchase, checkedPurchase, purchaseItemClick, baseUrl, chooseCreditsAndToPayPanel } = props;
    const [currentPanel, setCurrentPanel] = useState('credit');

    return (
        <>
            <div className={Styles.left}>
                <div className={Styles.left_top}>
                    <img src='https://resource.flexclip.com//pages/company/pricing/banner.png' alt='btn background' />
                    <button
                        className={`${Styles.buttonItem} 
                            ${currentPanel === 'credit' ? Styles.buttonItemActive : ''}`}
                        onClick={() => {
                            setCurrentPanel('credit');
                        }}
                    >
                        AI Credits
                    </button>
                    <button
                        className={`${Styles.buttonItem} 
                            ${currentPanel !== 'credit' ? Styles.buttonItemActive : ''}`}
                        onClick={() => {
                            setCurrentPanel('subscribe');
                        }}
                    >
                        Subscription
                    </button>
                </div>
                <div className={Styles.left_content}>
                    {currentPanel === 'credit' ? (
                        <CreditLeftPart
                            baseUrl={baseUrl}
                            purchase={purchase}
                            checkedPurchase={checkedPurchase}
                            purchaseItemClick={purchaseItemClick}
                            chooseCreditsAndToPayPanel={chooseCreditsAndToPayPanel}
                        />
                    ) : (
                        <SubscribeLeftPart />
                    )}
                </div>
            </div>
            <div className={Styles.right}>
                {currentPanel === 'credit' ? (
                    <img src={`${baseUrl}new-credit-credit.webp`} alt='credit img' className={Styles.credit_img} />
                ) : (
                    <SubscribeRightPart baseUrl={baseUrl} />
                )}
            </div>
        </>
    );
}
