import Style from './Credits.module.less';
import { useState, useLayoutEffect } from 'react';
import classnames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import mainAction from '../../../reducer/action/mainAction';

import FJLocalStore from '../../../store/FJLocalStore';
import InlineSvg from 'react-inlinesvg';

import CreditsSvg from '../../../icon/Credits.svg';
import checkSvg from '../../../icon/check.svg';
import addSvg from '../../../icon/icon-add2.svg';
import freeGiftSvg from '../../../icon/free-gift.svg';

import FJUtil from '../../../util/FJUtil';
import useIsMobile from '../../../hooks/useIsMobile';
import FJCreditsPackageViewContainer from '../../FJMobile/FJCreditsPackageView/FJCreditsPackageView.container';
import usePricePrefix from '../../../hooks/usePricePrefix';

export default function Credits(props) {
    // 是否是移动端，是的话，仅对积分套餐做展示，不做交互
    let isMobile = useIsMobile();
    const pricePrefix = usePricePrefix();
    const dispatch = useDispatch();

    const { purchase = [], getUserInfo = () => {}, changeCheckedCredits } = props;
    const creditsPayData = useSelector(state => state.mainReducer.creditsPayData);
    // const showCreditRuleTable = useSelector(state => state.mainReducer.showCreditRuleTable);
    const method = useSelector(state => state.mainReducer.method);
    const isPopup = method === 'popup';

    // 当前用户所在地区货币符号
    const currentCountryMonetary = useSelector(state => state.mainReducer.currentCountryMonetary);
    const currentCountryMonetaryISO = useSelector(state => state.mainReducer.currentCountryMonetaryISO);
    const exchangeRate = useSelector(state => state.mainReducer.exchangeRate);

    const [domWidth, setDomWidth] = useState(0);

    const checkedPurchase = creditsPayData.index;
    let __drawPurchaseView = () => {
        if (isMobile) {
            return <FJCreditsPackageViewContainer />;
        }
        let purchaseItemClick = index => {
            if (checkedPurchase === index) {
                return;
            }
            changeCheckedCredits(index);
        };

        return (
            <ul className={Style.purchase} style={{ '--boxWidth': domWidth * 2 + 13 + 'px' }}>
                {Array.isArray(purchase) &&
                    purchase.map((item, index) => {
                        return (
                            <li
                                key={index}
                                className={classnames(Style.purchaseItem, {
                                    [Style.purchaseItemActive]: checkedPurchase === index,
                                })}
                                onClick={() => {
                                    purchaseItemClick(index);
                                }}
                                style={{ '--width': domWidth + 'px' }}
                            >
                                <div className={Style.purchaseItemIconBox}>
                                    <InlineSvg src={CreditsSvg} />
                                </div>
                                <div
                                    className={classnames(Style.baseCreditsBox, {
                                        [Style.baseCreditsBoxWithNoPresent]: !item.present,
                                    })}
                                >
                                    {item.base}&nbsp;{FJLocalStore._(item.baseUnit)}
                                    {item.base < 1000 && <>&nbsp;&nbsp;&nbsp;</>}
                                </div>
                                {item.present ? (
                                    <>
                                        <div className={classnames(Style.purchaseItemIconBox, Style.addIcon)}>
                                            <InlineSvg src={addSvg} />
                                        </div>
                                        <div className={Style.purchaseItemIconBox}>
                                            <InlineSvg src={freeGiftSvg} />
                                        </div>
                                        <span className={Style.presentCreditsBox}>
                                            {item.present}&nbsp;
                                            {FJLocalStore._(item.presentUnit)}
                                        </span>
                                    </>
                                ) : (
                                    ''
                                )}
                                <div className={Style.purchaseItemPrice}>
                                    <span className={Style.currentCountryMonetary}>
                                        {currentCountryMonetary || item.priceUnit}
                                        {currentCountryMonetaryISO}
                                    </span>
                                    <span className={Style.currentCountryMonetaryPrice}>
                                        {FJUtil.formatNumberThousandth(
                                            FJUtil.preciseMultiply([parseFloat(item.price), exchangeRate]).toFixed(2),
                                        )}
                                    </span>
                                </div>
                                <div className={Style.purchaseItemChecked}>
                                    <InlineSvg src={checkSvg} />
                                </div>
                            </li>
                        );
                    })}
            </ul>
        );
    };

    const isInTeamSpace = window.fjuser?.info?.space_type === 'team';
    const planType = useSelector(state => state.mainReducer.planType);
    // 选择积分套餐后点击跳转去支付
    const chooseCreditsAndToPayPanel = () => {
        if (typeof checkedPurchase !== 'number') {
            return;
        }

        // 个人空间时，账号无论在普通积分面板还是在团队积分面板都购买的是个人积分
        // 团队空间时，账号在普通积分面板购买的积分是个人积分，在团队积分面板购买的是团队积分
        // 但是购买团队积分时，只有团队拥有者有权限买，普通团队成员会被提示没有权利购买团队积分
        if (isInTeamSpace && planType === 'team') {
            const permission = window.fjuser?.info?.space_items.find(
                item => item.id === window.fjuser.info.current_space_id,
            ).spacePermission;

            if (permission !== 'owner') {
                window.fjmessage.addMessage('非团队拥有者不能购买团队积分', 'error');
                return;
            }
            dispatch(mainAction.setIsTeamOwner(true));
            dispatch(mainAction.setTeamOwnerTid(window.fjuser.info.current_space_id));
        }

        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        getUserInfo && getUserInfo();
    };

    // let __drawRulesTable = () => {
    //     let __drawTableBodyRows = new Array(16).fill(null).map((item, index) => {
    //         return (
    //             <tr key={index} className={Style.tableBorder}>
    //                 <td align='left'>{FJLocalStore._(`rules-line-${index + 1}-1`)}</td>
    //                 <td>{FJLocalStore._(`rules-line-${index + 1}-2`)}</td>
    //             </tr>
    //         );
    //     });

    //     return (
    //         <table className={Style.rulesTable}>
    //             <thead>
    //                 <tr>
    //                     <th style={{ borderRight: '1px solid #E6E9ED' }} align='left'>
    //                         {FJLocalStore._('rules-line-0-1')}
    //                     </th>
    //                     <th>{FJLocalStore._('rules-line-0-2')}</th>
    //                 </tr>
    //             </thead>
    //             <tbody>{__drawTableBodyRows}</tbody>
    //         </table>
    //     );
    // };

    const jumpToPricingCredits = () => {
        const url = FJUtil.getCurrentUrl() + '/pricing?panelName=Credits';
        window.open(url, '_blank');
    };

    // 获取全部dom中最宽的元素
    let _calculateDomMaxWidth = () => {
        const liList = document.getElementsByClassName(Style.purchaseItem);
        let maxWidth = 0;
        for (let i = 0; i < liList.length; i++) {
            const rec = liList[i].getBoundingClientRect();
            maxWidth = Math.max(rec.width, maxWidth);
        }

        setDomWidth(maxWidth);
    };
    useLayoutEffect(() => {
        _calculateDomMaxWidth();
    });
    const boxStyle = isPopup
        ? {
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
          }
        : {};
    const purchaseInfo = creditsPayData.info;
    const price = purchaseInfo ? pricePrefix + purchaseInfo.price : '';
    const btnText = isMobile ? FJLocalStore._('get-now') + ' ' + price : FJLocalStore._('get-now');
    return (
        <div className={Style.Credits} style={boxStyle}>
            <div>
                <h2 className={Style.title}>{FJLocalStore._('credits-title')}</h2>
                <p className={Style.desc} dangerouslySetInnerHTML={{ __html: FJLocalStore._('credits-desc') }} />
                {__drawPurchaseView()}
            </div>
            <button className={Style.startNowBtn} onClick={chooseCreditsAndToPayPanel}>
                {btnText}
            </button>
            <p className={Style.creditsValidity}>
                {FJLocalStore._('CreditsValidity')}&nbsp;
                {isPopup && <span onClick={jumpToPricingCredits}>{FJLocalStore._('lern-more')}</span>}
            </p>
            {/* {showCreditRuleTable && !isPopup && (
                <>
                    <h3 className={Style.exchangeRules}>{FJLocalStore._('ExchangeRules')}</h3>
                    {__drawRulesTable()}
                </>
            )} */}
        </div>
    );
}
