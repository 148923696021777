import React from 'react';
import Styles from './ABTestBoxA.module.less';
import InlineSvg from 'react-inlinesvg';
import CorrectIcon from '../../../icon/correct.svg';
import FJUtil from '../../../util/FJUtil';

const jumpToPricingPage = () => {
    const url = FJUtil.getCurrentUrl() + '/pricing.html';
    window.open(url, '_blank');
};

export default function ABTestBoxA(props) {
    const { purchase, checkedPurchase, purchaseItemClick, baseUrl, chooseCreditsAndToPayPanel } = props;
    return (
        <>
            <div className={Styles.left}>
                <p className={Styles.left_title}>Get AI Credits</p>
                <p className={Styles.left_desc}>
                    Unlock 100+ editing features and AI tools, such as AI Enlarger, Magic Eraser, Background Remover and
                    more.
                </p>
                {purchase.map((item, index) => {
                    return (
                        <div
                            className={`${Styles.list_item} ${checkedPurchase === index ? Styles.active_item : ''}`}
                            key={'list' + index + 1}
                            onClick={() => {
                                purchaseItemClick(index);
                            }}
                        >
                            <div className={Styles.item_left}>
                                <div className={Styles.left_top}>
                                    <img
                                        className={Styles.left_top_icon}
                                        width={20}
                                        height={20}
                                        src={`${baseUrl}credits.webp`}
                                    />
                                    <p className={Styles.left_top_credit}>{item.base}</p>
                                </div>
                                <p className={Styles.left_bottom}>${(item.price / item.base).toFixed(3)}USD/Credits</p>
                            </div>
                            <div className={Styles.item_right}>
                                <span>US$</span>
                                <span>{item.price}</span>
                            </div>
                        </div>
                    );
                })}
                <button className={Styles.left_btn} onClick={chooseCreditsAndToPayPanel}>
                    Choose Now
                </button>
            </div>
            <div className={Styles.right}>
                <div className={Styles.content}>
                    <img src={`${baseUrl}new-credit-base.webp`} alt='credit img' width='100%' height='auto' />
                    <div className={Styles.text}>
                        <p>FlexClip Video Editor Subscription</p>
                        <p>
                            <InlineSvg src={CorrectIcon} />
                            <span>1080p Full-HD downloads</span>
                        </p>
                        <p>
                            <InlineSvg src={CorrectIcon} /><span>5 stock videos and audio/project</span>
                        </p>
                        <p>
                            <InlineSvg src={CorrectIcon} />
                            <span>Auto subtitles 720 mins/yr</span>
                        </p>
                        <p>
                            <InlineSvg src={CorrectIcon} />
                            <span>20 GB Cloud Space</span>
                        </p>
                        <p>
                            <InlineSvg src={CorrectIcon} />
                            <span>25 background remove credits/month</span>
                        </p>
                        <p>
                            <InlineSvg src={CorrectIcon} />
                            <span>No FlexClip watermark</span>
                        </p>
                        <button onClick={jumpToPricingPage}>Learn More</button>
                    </div>
                </div>
            </div>
        </>
    );
}
