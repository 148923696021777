import styles from './FJCreditsPackageView.module.less';
import InlineSvg from 'react-inlinesvg';
import CreditsPurpleSvg from '../../../icon/credit-purple.svg';
import classnames from 'classnames';
import FJLocalStore from '../../../store/FJLocalStore';
const FJCreditsPackageView = props => {
    const { purchase, changeSelectCredit, creditsPayData, pageMethod } = props;

    return (
        <div className={classnames(styles.purchase_box, styles[pageMethod])}>
            {purchase.map((item, index) => {
                return (
                    <li
                        key={index}
                        className={classnames(styles.purchase_item, {
                            [styles.active]: creditsPayData.index === index,
                        })}
                        onClick={() => {
                            changeSelectCredit(index);
                        }}
                    >
                        <div className={styles.purchase_info}>
                            <InlineSvg className={styles.credits_svg} src={CreditsPurpleSvg} />
                            <span className={styles.purchase_base}>{item.base}</span>
                        </div>
                        {item.present && (
                            <div className={styles.purchase_remark}>{FJLocalStore._('bonus') + ' ' + item.present}</div>
                        )}
                    </li>
                );
            })}
        </div>
    );
};
export default FJCreditsPackageView;
