import { useEffect, useMemo } from 'react';
import PackagePanelContainer from '../PackagePanel/PackagePanelContainer';
import PayPanel from '../PayPanel/PayPanel';
import { useDispatch, useSelector } from 'react-redux';
import mainAction from '../../reducer/action/mainAction';
import EndPanel from '../EndPanel/EndPanel';
import FJComparePlansContainer from '../FJComparePlans/container/FJComparePlans.container';
import store from '../../reducer/reducer/FJRoot';

const SubscribeStepContainer = props => {
    const init = () => {
        window.FJGlobalariable.modSubscription.network
            .getSubscription(false)
            .then(response => {
                // window.FJGlobalariable.modSubscription.updateSubscriptionInfo(response.data.subscription);
                let subscription = response.data.subscription;
                store.dispatch(
                    mainAction.setCurrentPlan({
                        package: subscription.package,
                        period: subscription.period,
                        price: subscription.price,
                        period_end: subscription.period_end,
                        status: subscription.status,
                        sub_type: subscription.sub_type ? subscription.sub_type : 'stripe',
                    }),
                );
                const teamSubscription = response.data.teamSubscription;
                if (teamSubscription) {
                    store.dispatch(mainAction.setTeamSubscription({ ...teamSubscription }));
                }

                if (store.getState().mainReducer.planType === 'team') {
                    store.dispatch(
                        mainAction.changePayMethodAction(
                            teamSubscription.sub_type && teamSubscription.sub_type !== 'exch'
                                ? teamSubscription?.sub_type
                                : 'stripe',
                        ),
                    );
                } else {
                    store.dispatch(
                        mainAction.changePayMethodAction(
                            subscription.sub_type && subscription.sub_type !== 'exch'
                                ? subscription.sub_type
                                : 'stripe',
                        ),
                    );
                }
            })
            .catch(error => {})
            .finally(() => {});

        let initCookie = search => {
            let result = /(\?|&)(coupon=.*?)(&|$)/.exec(search);
            if (result) {
                document.cookie =
                    result[2] + '; expires=' + new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toUTCString();
            }
        };
        initCookie(location.search);
    };

    let { popupType } = props;
    let dispatch = useDispatch();
    let { step, selectPage, planData, isAnnually, method } = useSelector(state => {
        return {
            step: state.mainReducer.step,
            selectPage: state.mainReducer.selectPage,
            planData: state.mainReducer.planData,
            isAnnually: state.mainReducer.isAnnually,
            method: state.mainReducer.method,
            discountConf: state.mainReducer.discountConf,
        };
    });

    useEffect(() => {
        window.ealog &&
            window.ealog.addEvent('choose plan page visit', {
                'open page': document.location.pathname,
            });
    }, []);

    let _changePlan = value => {
        window.isAnnually = value;
        dispatch(mainAction.updateIsAnnually(value));
    };
    let _changeStep = (stepNum, page) => {
        if (page) {
            window.selectPage = page;
            dispatch(mainAction.changeSelectPageAction(page));
        }
        dispatch(mainAction.changeStepAction(stepNum));
    };

    let selectDetail = useMemo(() => {
        let data = {};
        planData.forEach(item => {
            if (item.package === selectPage) {
                data = item;
            }
        });
        return data;
    }, [planData, selectPage]);

    useEffect(() => {
        if (step !== 3) {
            init();
        }

        if (document.getElementById('pricing-view-container')) {
            if (step === 2 || step === 3) {
                document.getElementById('pricing-view-container').style.display = 'none';
                dispatch(mainAction.showOrHiddenPanelButton(false));
            } else {
                document.getElementById('pricing-view-container').style.display = 'block';
                dispatch(mainAction.showOrHiddenPanelButton(true));
            }
        }
    }, [dispatch, step]);

    return (
        <>
            <PackagePanelContainer
                popupType={popupType}
                changeStep={_changeStep}
                isAnnually={isAnnually}
                changePlan={_changePlan}
                active={step === 1}
            />
            <FJComparePlansContainer
                active={step === 1 && method === 'single'}
                popupType={popupType}
                changePlan={_changePlan}
            />
            <PayPanel
                active={step === 2}
                popupType={popupType}
                changeStep={_changeStep}
                changePlan={_changePlan}
                planDetail={selectDetail}
                isAnnually={isAnnually}
            />
            {step === 3 && <EndPanel isAnnually={isAnnually} />}
        </>
    );
};
export default SubscribeStepContainer;
