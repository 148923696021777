import Style from './PaypalBtn.module.less';
import { useEffect, useRef, useState } from 'react';
import FJLocalStore from '../../store/FJLocalStore';
import mainAction from '../../reducer/action/mainAction';
import FJMessageStore from '../../store/FJMessageStore';
import { useDispatch, useSelector } from 'react-redux';
import FJNetworkStore from '../../store/FJNetworkStore';
import store from '../../reducer/reducer/FJRoot';
import FJUtil from '../../util/FJUtil';
import paypalBtnBase64 from '../../assets/base64/paypay-btn-base64';
let PaypalBtn = props => {
    let { style, paypalOnClick } = props;
    const dispatch = useDispatch();

    const dataInfo = useSelector(state => state.mainReducer.dataInfo);
    const currentCountryMonetaryISO = useSelector(state => state.mainReducer.currentCountryMonetaryISO);
    const { info: currentCheckedCreditPackage } = useSelector(state => state.mainReducer.creditsPayData);
    const entry = useSelector(state => state.mainReducer.entry);
    const isTeamOwner = useSelector(state => state.mainReducer.isTeamOwner);
    const teamOwnerTid = useSelector(state => state.mainReducer.teamOwnerTid);

    let buttons = useRef(null);
    let hasRendered = useRef(false);
    let cityCodeRef = useRef('cn');
    let [hasPop, setHasPop] = useState(false);

    useEffect(() => {
        _getCityCode();
        return () => {
            if (buttons.current && buttons.current.close && hasRendered.current) {
                buttons.current.close();
                hasRendered.current = false;
                buttons.current = null;
            }
        };
    }, []);
    let _getCityCode = () => {
        try {
            FJNetworkStore.getUserCountryCode().then(countryCode => {
                cityCodeRef.current = countryCode;
            });
        } catch (e) {}
    };

    // 这个方法运行时机是后端关闭paypal窗口后，窗口关闭后，就能知道当前paypal订单支付状态，根据支付状态做相应界面操作
    window.getCrediteSubscribeId = (subscriptionID, boolean, code) => {
        let total = 0;
        let beginTime = Date.now();

        let _checkSubscription = () => {
            dispatch(mainAction.changeLoadingStatusAction(true));

            // 返回paypal支付订单id，状态成功后，执行界面操作，不成功，又执行相应操作
            window.FJGlobalariable.modSubscription.network
                .checkPaypalCreditsSubscriptionStatus(subscriptionID)
                .then(res => {
                    // code 200 成功 400 支付中 500 参数错误
                    if (res.code === 200) {
                        const payInfo = res.data && res.data.credits instanceof Object ? res.data.credits : {};
                        dispatch(
                            mainAction.setDataInfo({
                                amount: payInfo.price,
                                period_end: payInfo.period_end,
                                transaction_id: payInfo.transaction_id,
                            }),
                        );
                        dispatch(mainAction.changeLoadingStatusAction(false));
                        // 打开支付成功的问卷页面
                        store.dispatch(mainAction.changeBuyCreditsSuccessPage(true));

                        // 这里配合相关页面以及login-button等更新积分
                        if (window.fj && window.fj.event && window.fj.event.dispatch) {
                            window.fj.event.dispatch('UPDATE_USER_CREDITS');
                        }

                        FJUtil.ealog('ai credits revenue', {
                            'credit type':
                                currentCheckedCreditPackage.base +
                                (currentCheckedCreditPackage.present ? `+${currentCheckedCreditPackage.present}` : ''),
                            'coupon code off':
                                dataInfo.appliedCoupon &&
                                (dataInfo.couponObject.amount_off || dataInfo.couponObject.percent_off),
                            platform: 'paypal',
                            'coupon code': dataInfo.appliedCoupon,
                            location: cityCodeRef.current,
                            price: payInfo.price,
                            'ai tools': entry,
                        });
                    }

                    if (res.code === 400) {
                        let tryAgain = true;
                        total += 1;
                        if (total > 30 && Date.now() > beginTime + 30 * 1000) {
                            tryAgain = false;
                        }
                        if (tryAgain) {
                            setTimeout(() => {
                                _checkSubscription();
                            }, 1000);
                        }
                    }
                })
                .catch(err => {
                    let tryAgain = true;
                    if (err.type === 'ajax') {
                        total += 1;
                        if (total > 30 && Date.now() > beginTime + 30 * 1000) {
                            tryAgain = false;
                        }
                    }
                    if (tryAgain) {
                        _checkSubscription(500);
                    } else {
                        FJMessageStore.addMessage(FJLocalStore._('CONNECT_NETWORK_FAILED'), 'error');
                        // window.FJGlobalariable.modSubscription.showLoading = false;
                        dispatch(mainAction.changeLoadingStatusAction(false));
                    }
                });
        };
        if (boolean) {
            _checkSubscription();
        } else {
            FJUtil.checkLogin(code);
        }
    };

    const paypalRunning = () => {
        paypalOnClick && paypalOnClick();
        if (hasPop) {
            return;
        }
        setHasPop(true);
        // 当前没有选择的积分包则不进行后续操作
        if (!currentCheckedCreditPackage || !currentCheckedCreditPackage.packageName) {
            return;
        }

        const paypalWindow = window.open(
            '',
            '_blank',
            'height=600,width=600,status=yes,top=200,left=400,toolbar=no,menubar=no,location=no',
        );

        if (paypalWindow) {
            FJUtil.ealog('subscribe browser permissions', { state: 'disable', payType: 'paypal' });
        } else {
            FJUtil.ealog('subscribe browser permissions', { state: 'available', payType: 'paypal' });
        }

        window.FJGlobalariable.modSubscription.network
            .getPayPalCreditsWindowUrl(
                currentCheckedCreditPackage.packageName,
                dataInfo.appliedCoupon,
                currentCountryMonetaryISO,
                isTeamOwner ? teamOwnerTid : null,
            )
            .then(response => {
                if (response.code === 200 && response.data?.approvalUrl) {
                    return new Promise((resolve, reject) => {
                        paypalWindow.location.href = response.data.approvalUrl;
                        paypalWindow.opener = window;
                        const loop = setInterval(() => {
                            try {
                                if (paypalWindow.closed) {
                                    clearInterval(loop);
                                    resolve();
                                }
                            } catch (e) {
                                clearInterval(loop);
                                reject(e);
                            }
                        }, 100);
                    });
                } else {
                    paypalWindow && paypalWindow.close && paypalWindow.close();
                    throw new Error(response.code);
                }
            })
            .catch(error => {
                if (error?.message === '429') {
                    FJMessageStore.addMessage(FJLocalStore._('FAIL_WITH_TOO_MANY_REQUESTS'), 'error');
                } else {
                    FJMessageStore.addMessage(FJLocalStore._('CONNECT_NETWORK_FAILED'), 'error');
                }
            })
            .finally(() => setHasPop(false));
    };
    return (
        <>
            <div className={Style.paypal_box_new} style={style} onClick={paypalRunning}>
                <img width={63} height={20} src={paypalBtnBase64} alt='' />
            </div>
            {hasPop && (
                <div className={Style.mask_box}>
                    <div className={Style.mask} />
                </div>
            )}
        </>
    );
};
export default PaypalBtn;
