import React from 'react';
import Styles from './BaseBox.module.less';

export default function BaseBox(props) {
    const { purchase, checkedPurchase, purchaseItemClick, baseUrl, chooseCreditsAndToPayPanel } = props;

    return (
        <>
            <div className={Styles.left}>
                <p className={Styles.left_title}>Get AI Credits</p>
                <p className={Styles.left_desc}>
                    Unlock 100+ editing features and AI tools, such as AI Enlarger, Magic Eraser, Background Remover and
                    more.
                </p>
                {purchase.map((item, index) => {
                    return (
                        <div
                            className={`${Styles.list_item} ${checkedPurchase === index ? Styles.active_item : ''}`}
                            key={'list' + index + 1}
                            onClick={() => {
                                purchaseItemClick(index);
                            }}
                        >
                            <div className={Styles.item_left}>
                                <div className={Styles.left_top}>
                                    <img
                                        className={Styles.left_top_icon}
                                        width={20}
                                        height={20}
                                        src={`${baseUrl}credits.webp`}
                                    />
                                    <p className={Styles.left_top_credit}>{item.base}</p>
                                </div>
                                <p className={Styles.left_bottom}>${(item.price / item.base).toFixed(3)}USD/Credits</p>
                            </div>
                            <div className={Styles.item_right}>
                                <span>US$</span>
                                <span>{item.price}</span>
                            </div>
                        </div>
                    );
                })}
                <button className={Styles.left_btn} onClick={chooseCreditsAndToPayPanel}>
                    Choose Now
                </button>
                <p className={Styles.tip}>Credites validity: 365 days from date of purchase.</p>
            </div>
            <div className={Styles.right}>
                <img src={`${baseUrl}new-credit-credit.webp`} alt='credit img' width={460} height={620} />
            </div>
        </>
    );
}
