import { useDispatch, useSelector } from 'react-redux';
import FJPaySuccess from './FJPaySuccess';
import FJMobilePayAction from '../../../reducer/action/FJMobilePayAction';
import FJViewConstant from '../../../constant/FJViewConstant';
import FJUtil from '../../../util/FJUtil';
import mainAction from '../../../reducer/action/mainAction';

const FJPaySuccessContainer = () => {
    const dispatch = useDispatch();
    const method = useSelector(state => state.mainReducer.method);
    const exchangeRate = useSelector(state => state.mainReducer.exchangeRate);

    const closePanel = () => {
        if (FJUtil.isLocalhost()) {
            dispatch(FJMobilePayAction.setMobilePayStep(FJViewConstant.PAYMENT_METHOD));
        } else {
            if (method === 'popup') {
                dispatch(mainAction.closeSubscribeView());
            } else {
                dispatch(FJMobilePayAction.setMobilePayStep(FJViewConstant.NONE));
            }
        }
    };
    const startNow = () => {
        if (method === 'popup') {
            closePanel();
        } else {
            location.href = location.origin + '/tools';
        }
    };

    return <FJPaySuccess closePanel={closePanel} startNow={startNow} exchangeRate={exchangeRate} />;
};
export default FJPaySuccessContainer;
